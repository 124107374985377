import React, { useState } from 'react';
import axios from 'axios';

function Contact(props) {
    const [formData, setFormData] = useState({ name: '', phone: '' });
    const [flag, setFlag] = useState(false);

    // עדכון הסטייט עם שינוי בערכי הקלטים
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            await axios.post("https://amircohennewsitebe.onrender.com/users", formData);
            setFlag(true);
        } catch (error) {
            console.error("Error submitting the form:", error);
        }
    };

    return (
        <>
            <section id="contact" className="contact-section">
                <div className="contact-form">
                    <h2>צרו קשר</h2>
                    <form onSubmit={handleSubmit}>
                        <div className="form-group">
                            <input
                                type="text"
                                name="name"
                                placeholder="שם מלא"
                                value={formData.name}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className="form-group">
                            <input
                                type="tel"
                                name="phone"
                                placeholder="טלפון"
                                value={formData.phone}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <button type="submit">שליחה</button>
                    </form>
                </div>

                {flag && (
                    <p style={{ fontSize: "28pt", textAlign: "center", color: "#D4B062" }}>
                        תודה רבה, נחזור אליכם בהקדם!
                    </p>
                )}
            </section>

        </>
    );
}

export default Contact;
