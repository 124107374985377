import React from 'react';
import pic1 from '../images/pic1.jpg'
import pic2 from '../images/pic2.jpg'
import pic3 from '../images/pic3.jpg'
import pic5 from '../images/pic5.jpg'
import pic6 from '../images/pic6.jpg'
import pic7 from '../images/pic7.jpg'
import pic8 from '../images/pic8.jpg'
import pic9 from '../images/pic9.jpg'

function Carousel(props) {
    return (
        <section id="gallery" class="gallery">
            <div class="gallery-item">
                <img src={pic1} alt="יצירת אמנות 1" />
            </div>
            <div class="gallery-item">
                <img src={pic2} alt="יצירת אמנות 2" />
            </div>
            <div class="gallery-item">
                <img src={pic3} alt="יצירת אמנות 3" />
            </div>
            <div class="gallery-item">
                <img src={pic5} alt="יצירת אמנות 5" />
            </div>
            <div class="gallery-item">
                <img src={pic6} alt="יצירת אמנות 6" />
            </div>
            <div class="gallery-item">
                <img src={pic7} alt="יצירת אמנות 7" />
            </div>
            <div class="gallery-item">
                <img src={pic8} alt="יצירת אמנות 8" />
            </div>
            <div class="gallery-item">
                <img src={pic9} alt="יצירת אמנות 9" />
            </div>
        </section>
    );
}

export default Carousel;