import Menu from './components/Menu';
import About from './components/About';
import Contact from './components/Contact';
import Footer from './components/Footer';
import Carousel from './components/Carousel';
import Header from './components/Header';

import './App.css';


function App() {
  return (
    <div className="App">
      <Menu />
      <Header />
      <About />
      <Carousel />
      <Contact />
      <Footer />
    </div>
  );
}

export default App;
