import React from 'react';

function About(props) {

    return (
        <section id="about" class="about-section">
            <div class="about-content">
                <div class="about-text">
                    <h2>בנייה פרטית א'-ת'</h2>
                    <p>חברת Cohen Projects מתמחה בבנייה פרטית יוקרתית, תוך שימת דגש על איכות בלתי מתפשרת ותשומת לב לפרטים
                        הקטנים ביותר.</p>
                    <p>אנו מלווים את הלקוחות שלנו לאורך כל הדרך, מהתכנון הראשוני ועד למסירת המפתח, תוך הקפדה על סטנדרטים
                        גבוהים ועמידה בלוחות זמנים.</p>
                    <p>הצוות המקצועי שלנו כולל אנשי מקצוע מהשורה הראשונה, בעלי ניסיון רב בתחום הבנייה היוקרתית.</p>
                </div>
            </div>
        </section>
    );
}

export default About;