import React from 'react';

import logo from '../images/logo.png'

function Header(props) {
    return (
        <header id="home" class="header">
            <img src={logo} alt="Cohen Projects Logo" class="logo" />
            <div class="features">
                <div class="feature-box">הנדסאי בניין</div>
                <div class="feature-box">קבלן רשום</div>
                <div class="feature-box">מוניטין רב שנים</div>
                <div class="feature-box">100% עמידה בזמנים</div>
            </div>
        </header>
    );
}

export default Header;