import React from 'react';

function Menu(props) {
    return (
        <nav>
            <a href="#home">בית</a>
            <a href="#about">אודות</a>
            <a href="#gallery">פרויקטים</a>
            <a href="#contact">צור קשר</a>
        </nav>
    );
}

export default Menu;